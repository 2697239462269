import React from "react"
import { Link } from "gatsby"
import { Linkedin, Instagram, Send, Youtube } from "react-feather"
import containerClasses from "../../styles/container.module.css"
import logoWhite from "../../assets/icons/logo-white.svg"

type FooterProps = {
  siteTitle: string
  productItems: {
    oculoplasticServices: {
      edges: Array<any>
    }
    aestheticServices: {
      edges: Array<any>
    }
    conditions: {
      edges: Array<any>
    }
  }
}

export const Footer = ({ productItems }: FooterProps) => (
  <>
    <div className="text-white bg-blackNew">
      <div
        className={`${containerClasses.container} grid grid-rows-[auto,1fr,1fr,1fr,1fr] pt-8 md:pt-12 pb-2 gap-4`}
      >
        <div className="col-span-4 md:col-span-8 lg:col-span-12">
          <img
            alt="Dr Hawkes logo"
            className="h-10 my-4 lg:h-12"
            src={logoWhite}
          />
        </div>
        {/* Oculoplastic Services */}
        <div className="col-span-4 row-span-1 md:col-span-8 lg:col-span-3">
          <nav>
            <p className="my-2 text-sm font-bold text-white uppercase">
              Oculoplastic Services
            </p>
            <div className="mt-1 mb-1 border-solid border-b-1 border-onDarkLowEmphasis"></div>
            <ol>
              {productItems?.oculoplasticServices?.edges.map(({ node }, id) => (
                <li
                  className="pb-1 text-xs text-white transition-all duration-500 ease-in-out hover:underline hover:text-secondaryBrand-8"
                  key={`footerProductsLink-${id}`}
                >
                  <Link
                    to={`${node?.childMarkdownRemark?.frontmatter?.path}`}
                    className="block font-sans text-xs text-left text-white py-14"
                  >
                    {node?.childMarkdownRemark?.frontmatter?.title}
                  </Link>
                </li>
              ))}
            </ol>
          </nav>
        </div>
        {/* Aesthetic Services */}
        <div className="col-span-4 row-span-1 md:col-span-8 lg:col-span-3">
          <nav>
            <p className="my-2 text-sm font-bold text-white uppercase">
              {" "}
              Aesthetic Services
            </p>
            <div className="mt-1 mb-1 border-solid border-b-1 border-onDarkLowEmphasis"></div>
            <ol>
              {productItems?.aestheticServices?.edges.map(({ node }, id) => (
                <li
                  className="pb-1 text-xs text-white transition-all duration-500 ease-in-out hover:underline hover:text-secondaryBrand-8"
                  key={`footerProductsLink-${id}`}
                >
                  <Link
                    to={`${node?.childMarkdownRemark?.frontmatter?.path}`}
                    className="block font-sans text-xs text-left text-white py-14"
                  >
                    {node?.childMarkdownRemark?.frontmatter?.title}
                  </Link>
                </li>
              ))}
            </ol>
          </nav>
        </div>
        {/* Eye Conditions */}
        <div className="col-span-4 row-span-1 md:col-span-8 lg:col-span-3">
          <nav>
            <p className="my-2 text-sm font-bold text-white uppercase">
              {" "}
              Eye Conditions
            </p>
            <div className="mt-1 mb-1 border-solid border-b-1 border-onDarkLowEmphasis"></div>
            <ol>
              {productItems?.conditions?.edges.map(({ node }, id) => (
                <li
                  className="pb-1 text-xs text-white transition-all duration-500 ease-in-out hover:underline hover:text-secondaryBrand-8"
                  key={`footerProductsLink-${id}`}
                >
                  <Link
                    to={`${node?.childMarkdownRemark?.frontmatter?.path}`}
                    className="block font-sans text-xs text-left text-white py-14"
                  >
                    {node?.childMarkdownRemark?.frontmatter?.title}
                  </Link>
                </li>
              ))}
            </ol>
          </nav>
        </div>
        {/* Contact */}
        <div className="col-span-4 row-span-1 md:col-span-8 lg:col-span-3">
          <p
            className="my-2 text-sm font-bold text-white uppercase"
            id="footer"
          >
            Stay Up To Date
          </p>
          <div className="mt-1 mb-1 border-solid border-b-1 border-onDarkLowEmphasis"></div>
          <form
            name="newsletter"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="flex mt-4">
              <input type="hidden" name="form-name" value="newsletter" />
              <input type="hidden" name="bot-field" />
              <label className="flex flex-grow">
                <span className="sr-only">Sign up</span>
                <input
                  className="flex-grow pl-4 text-xs placeholder-white bg-transparent border-white border-solid lg:w-full border-1"
                  type="email"
                  placeholder="Email address"
                  id="newsletter-signup"
                  name="newsletter-signup"
                  required
                />
                <button className="p-3 text-white">
                  <span className="sr-only">Apply for newsletter</span>
                  <Send />
                </button>
              </label>
            </div>
          </form>
          <p className="mt-3 mb-6 text-xs text-gray-300">
            Please sign up to the newsletter if interested.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/dr_elizabethhawkes/"
          >
            <Instagram className="inline mr-2 text-white" />
            <span className="sr-only">Instagram</span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2"
            href="https://www.linkedin.com/in/elizabeth-hawkes-84410588/"
          >
            <Linkedin className="inline text-white" />
            <span className="sr-only">Linked in</span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="h-12 ml-2"
            href="https://www.youtube.com/channel/UC3WWB-mYueUxIRk6IXrJDew"
          >
            <Youtube className="inline text-white" />
            <span className="sr-only">Linked in</span>
          </a>
        </div>
      </div>
    </div>
    <div className="py-4 text-white bg-blackNew">
      <div
        className={`${containerClasses.container} px-6 mx-auto sm:px-8 md:px-12`}
      >
        <div className="flex flex-col col-span-4 md:col-span-8 lg:col-span-12 lg:flex-row">
          <div className="flex flex-row flex-wrap space-between">
            <p className="mb-2 mr-4 text-xs text-white">
              &copy; Copyright Harley Street Eyesthetics Ltd. All Rights
              Reserved.
            </p>
            <Link
              className="mr-4 text-xs font-light text-white underline"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
            <Link
              className="mr-4 text-xs font-light text-white underline"
              to="/terms-and-conditions"
            >
              Terms &amp; Conditions
            </Link>
            <Link
              className="mr-4 text-xs font-light text-white underline"
              to="/complaints"
            >
              Complaints
            </Link>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="bg-blackNew">
      <div
        className={`${containerClasses.container} col-span-4  md:col-span-8 l px-6 py-4 mx-auto sm:px-8 md:px-12`}
      >
        <div className="col-span-4">
          <p className="text-xs text-white">
            Made by{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-xs text-white underline transition-all duration-500 ease-in-out hover:underline hover:text-secondaryBrand-8"
              href="https://www.instagram.com/jessicabrownless/?hl=en/"
            >
              Jess Brownless Design
            </a>
          </p>
        </div>
      </div>
    </div> */}
  </>
)

export default Footer

import React from "react"
import { Link } from "gatsby"
import { Menu } from "react-feather"
import { CMSSubNav } from "./CMSSubNav"
import { SubMenuItem } from "./SubMenuItem"
import { useScroll } from "../../utils/functions"
import { Instagram } from "react-feather"
import logo from "../../assets/icons/logo.svg"
import youtube from "../../assets/icons/youtube.svg"
import tatler from "../../assets/icons/tatler-white.svg"
import royalCollege from "../../assets/images/royal-college-white-no-text.svg"
import aesthetics from "../../assets/images/aesthetics-white.svg"
import linkedin from "../../assets/icons/linkedin.svg"
import styles from "./header.module.css"
import containerClasses from "../../styles/container.module.css"
import buttons from "../../styles/buttons.module.css"

type HeaderProps = {
  siteTitle: string
  navLinks: Array<{
    name: string
    nameShort: string
    page: string
    id: number
    subLinks?: Array<{ name: string; page: string; id: number }>
  }>
  productItems: object
  navToggle: () => Function
  openMenu: number
  menuToggle: () => Function
  headerBg?: string
  headerColor?: string
  page: string
}

const Navigation = ({
  navLinks,
  openMenu,
  menuToggle,
  productItems,
  page: pageRendered,
}) => {
  return (
    <nav>
      <ol className={`${styles.headerNavigationList} flex justify-between`}>
        {navLinks.map(({ name, nameShort, page, subLinks, id, products }) => {
          if (products) {
            let data

            // @ts-ignore: graphql data
            if (products === "conditions") {
              data = productItems.conditions
            }

            // @ts-ignore: graphql data
            if (products === "oculoplasticServices") {
              data = productItems.oculoplasticServices
            }

            // @ts-ignore: graphql data
            if (products === "aestheticServices") {
              data = productItems.aestheticServices
            }

            data.edges.sort((a, b) =>
              a.node.childMarkdownRemark.frontmatter.id >
              b.node.childMarkdownRemark.frontmatter.id
                ? 1
                : -1
            )

            const productsDestructured = data.edges.map(
              obj =>
                // @ts-ignore: graphql data
                obj.node.childMarkdownRemark.frontmatter
            )

            return (
              <CMSSubNav
                page={pageRendered === products}
                key={`navigation-item-${name}`}
                name={name}
                nameShort={nameShort}
                title="Treatments"
                items={productsDestructured}
                id={id}
                open={openMenu === id}
                openTrigger={menuToggle}
              />
            )
          }

          if (subLinks && subLinks.length > 0) {
            return (
              <SubMenuItem
                key={`navigation-item-${id}`}
                name={name}
                subLinks={subLinks}
                id={id}
                open={openMenu === id}
                openTrigger={menuToggle}
              />
            )
          }
          return (
            <li
              className={`${styles.headerNavigationLinkItem} ${
                name === pageRendered ? `afterBorder` : ``
              }`}
              data-testid="headerNavigationLink"
              key={`navigation-item-${id}`}
            >
              <Link className={styles.headerNavigationLink} to={`${page}/`}>
                {nameShort ? (
                  <>
                    <span className="font-medium xl:hidden">{nameShort}</span>
                    {/* This styles the header link In The Press */}
                    <span className="hidden text-xs font-medium uppercase xl:block">
                      {name}
                    </span>
                  </>
                ) : (
                  <span>{name}</span>
                )}
              </Link>
            </li>
          )
        })}
        <li
          className={`${styles.headerNavigationLinkItem} ${
            pageRendered === "Contact" ? `afterBorder` : ``
          }`}
        >
          <Link className={styles.headerNavigationLink} to="/contact/">
            Contact
          </Link>
        </li>
      </ol>
    </nav>
  )
}
export const Header = ({
  siteTitle,
  navLinks,
  navToggle,
  openMenu,
  menuToggle,
  headerBg,
  headerColor,
  productItems,
  page,
}: HeaderProps) => {
  const { scrollY, scrollDirection } = useScroll()

  return (
    <div
      className={` 
      ${headerBg ? headerBg : "bg-white shadow pt-6 lg:pb-0 xl:pt-8"}
      ${headerColor ? headerColor : ""}
      ${scrollY > 400 ? styles.headerContainerFixed : ""}
      ${
        scrollY > 400 && scrollDirection === "down"
          ? styles.headerContainerRetreat
          : ""
      }
       ${styles.headerContainer}`}
    >
      <div
        className={`text-blackNew flex items-center justify-center md:justify-between ${containerClasses.container}`}
      >
        <div className="flex col-span-4 mb-4 xl:mb-6 md:col-span-8 lg:col-span-12">
          <Link className="flex-grow lg:block" to="/">
            <img
              loading="lazy"
              width="256"
              height="32"
              className={`${styles.headerLogoImage} lg:h-40 pr-0!`}
              src={logo}
              alt={siteTitle}
            />
          </Link>
          <div className="flex items-center">
            <a
              href="/contact"
              className={`text-center hidden mr-4 md:block rounded px-24 ${buttons.btnLargeExpressive}`}
            >
              Book now
            </a>
            <Menu onClick={navToggle} className={styles.headerMenuIcon} />
            {/* Socials */}
            <div className="items-center hidden lg:flex">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/dr_elizabethhawkes/"
                className="p-1 mr-3 text-black"
              >
                <Instagram />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/@DrElizabethHawkes"
                className="p-1 mr-2 text-blackNew"
              >
                <img src={youtube} alt="Youtube Logo" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/elizabeth-hawkes-84410588/"
                className="p-1 text-blackNew"
              >
                <img src={linkedin} alt="LinkedIn Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Menu items */}
      <div className="hidden text-sm border-solid bg-white2 lg:border-t-0 lg:border-none lg:h-10 border-t-1 lg:block border-borderColour ">
        <div className={containerClasses.container}>
          <div className="col-span-4 md:col-span-8 lg:col-span-12">
            <Navigation
              productItems={productItems}
              navLinks={navLinks}
              openMenu={openMenu}
              menuToggle={menuToggle}
              page={page}
            />
          </div>
        </div>
      </div>
      {/* USPs */}
      <div className="col-span-4 md:col-span-8 lg:col-span-12">
        <div
          className={`bg-royalBlue py-1 hidden ${
            scrollY > 500 ? "hidden" : "lg:block"
          }`}
        >
          <div
            className={`
      ${containerClasses.container}
      `}
          >
            <div className="flex justify-between md:col-span-8 lg:col-span-12">
              <div className="flex flex-row items-center">
                {" "}
                <img
                  loading="lazy"
                  alt=""
                  className="h-4 mr-3 lg:mt-0"
                  src={aesthetics}
                />
                <p className="mb-0 font-mono text-xs leading-none text-white uppercase lg:mt-0">
                  Journal Advisor
                </p>
              </div>
              <div className="flex flex-row items-center">
                <img
                  loading="lazy"
                  alt=""
                  className="h-4 mr-3 lg:mt-0"
                  src={tatler}
                />
                <p className="mb-0 font-mono text-xs leading-none text-white uppercase lg:mt-0">
                  Recommended Surgeon
                </p>
              </div>
              <div className="flex flex-row items-center">
                <img
                  loading="lazy"
                  alt=""
                  className="h-4 mr-3 lg:mt-0"
                  src={royalCollege}
                />
                <p className="mb-0 font-mono text-xs leading-none text-white uppercase lg:mt-0">
                  RCOphth Examiner
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { ChevronDown, ChevronUp } from "react-feather"
import helpers from "../../styles/helpers.module.css"
import styles from "./header.module.css"

type CMSSubNavProps = {
  name: string
  nameShort: string
  items: Array<itemsType>
  id: number
  title: string
  open: boolean
  page: boolean
  openTrigger: (id: number) => Function
}

type itemsType = {
  id: number
  title: string
  path: string
}

export const CMSSubNav = ({
  name,
  nameShort,
  items,
  id,
  open,
  page,
  openTrigger,
}: CMSSubNavProps) => {
  return (
    <li
      className={`relative ${styles.headerNavigationLinkItem} ${
        page ? `afterBorder` : ``
      }`}
      tabIndex={0}
      key={`navigation-item-${id}`}
      data-testid="subLinkMenu"
      data-cy={`subLinkMenu-${name}`}
      onClick={e => {
        if (open) {
          openTrigger(0)
        } else {
          e.stopPropagation()
          openTrigger(id)
        }
      }}
    >
      {/* This styles Oculoplastic Services, Aesthetic Services and Conditions in Desktop Menu */}
      <span className="flex items-center py-1 font-mono font-medium tracking-wider uppercase text-blackNew">
        {nameShort ? (
          <>
            <span className="xl:hidden font-13">{nameShort}</span>
            <span className="hidden xl:block font-13">{name}</span>
          </>
        ) : (
          <span>{name}</span>
        )}
        {open ? (
          <ChevronUp className="ml-1 mt-2px" size="16" />
        ) : (
          <ChevronDown className="ml-1 mt-2px" size="16" />
        )}
      </span>

      <div
        onClick={e => e.stopPropagation()}
        className={`${
          open ? "block pointer-events-none absolute top-4 bg-white" : "hidden"
        }
        ${helpers.cmsContainer}
        `}
      >
        <div className="px-12 py-12 border-solid cursor-default pointer-events-auto border-t-1 border-b-1 border-borderColour">
          <ul
            className={`grid grid-flow-col w-content grid-rows-${Math.ceil(
              items.length / 2
            )} gap-6`}
          >
            {items.map(({ id, title, path }) => (
              <li className="" key={`CMSSubNavItem-${title}`}>
                <a className="capitalize" href={`${path}`}>
                  <div className="font-sans text-sm">{title}</div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </li>
  )
}
